import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useParams } from "react-router";
import { Milliseconds } from "../../../common/enum";
import { IDockDisplay } from "../../../models/management/dockDisplay/IDockDisplay";
import { fetchManagementDockDisplayAction } from "../../../redux/actions/management/dockDisplay/dockDisplay";
import { DigitalTimer } from "../../common/digitalTimer/digitalTimer";
import { EnvironmentDescription } from "../../common/environment/environment";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ManagementDockDisplayAssignedVehicles } from "./assigned/assigned";
import { ManagementDockDisplayContainerStyle } from "./containerStyle.jss";
import { ManagementDockDisplayWaitingVehicles } from "./waiting/waiting";
import { TitleH2, TitleH4 } from "../../common/titles/titles";

export const ManagementDockDisplayContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.DOCK_DISPLAY" });
    const { organization } = useParams();
    const theme = useTheme();
    const style = ManagementDockDisplayContainerStyle({ theme });
    const [dockDisplay, setDockDisplay] = useState<IDockDisplay | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setDockDisplay(null);
                const result = await fetchManagementDockDisplayAction(organization);
                setDockDisplay(result);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, Milliseconds.TWENTY_SEC);
        return () => clearInterval(intervalId);
    }, [organization]);
    
    const RenderWaitingVehicles = useMemo(() => <ManagementDockDisplayWaitingVehicles displayWaiting={dockDisplay?.waiting} />, [dockDisplay]);

    const RenderAssignedVehicles = useMemo(
        () =>
            dockDisplay?.assigned?.map((item) => (
                <ManagementDockDisplayAssignedVehicles
                    key={item.transportUnitNumber}
                    vehicle={item}
                />
            )),
        [dockDisplay],
    );

    return (
        <>
            <NavHeaderComponent
                title={t("TITLE")}
                endAction={
                    <>
                        <EnvironmentDescription />
                        <DigitalTimer className={style.timer} />
                    </>
                }
                action={
                    <TitleH4
                        className={style.leyend}
                        title={t("LEYEND")}
                    />
                }
                environmentBackground
            />
            <div className={style.generalContainer}>
                <div className={style.waiting}>
                    <div className={style.header}>{t("WAIT_VEHICLE")}</div>
                    <div className={style.dataGrid}>{RenderWaitingVehicles}</div>
                </div>

                <div className={style.assigned}>
                    <div className={style.header}>{t("VEHICLE_ASSIGNMENT")}</div>
                    <div className={style.data}>{RenderAssignedVehicles}</div>
                </div>
            </div>
        </>
    );
};
