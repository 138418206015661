import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { PopUpConfirmation } from "../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { componentsMediaQueries } from "../../../../../../common/constants";
import { patchEditRequestCustomerNotified } from "../../../../../../redux/actions/request/edit/action/requestEdit";
import { useTheme } from "react-jss";
import { ControllerStyle } from "./controllerStyle.jss";
import { PopUpConfirmationType } from "../../../../../../common/enum/PopUpConfirmationType";
import { RoleType } from "../../../../../../common/enum";

export const EditRequestCustomerNotificationController = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = ControllerStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    
    const isMobile = useMediaQuery({ query: componentsMediaQueries.MOBILE });
    const { showCustomerNotificationMessage, id } = useAppSelector((state) => state.editRequest.headerRequest);
    const { roles } = useAppSelector((state) => state.userData.profile);
    
    const isCustomerRole = useMemo(() => {
        return roles.find((item) => item.id === RoleType.CUSTOMER_ADMIN || item.id === RoleType.CUSTOMER_USER) ? true : false;
    }, [roles]);

    const showPopup = useMemo(() => {
        return isCustomerRole && showCustomerNotificationMessage ? true : false;
    }, [isCustomerRole, showCustomerNotificationMessage]);


    const onAcceptPopUp = () => {
        dispatch(patchEditRequestCustomerNotified(String(id)));
    };

    return (
        <PopUpConfirmation
            message={t("INFO.CUSTOMER_NOTIFICATION")}
            showPopUp={showPopup}
            onCancel={onAcceptPopUp}
            onAccept={onAcceptPopUp}
            type={PopUpConfirmationType.INFO}
            hiddeCancelButon={true}
            width={isMobile ? "80vw" : "calc(70vw - 10rem )"}
            iconSize={"3rem"}
            height={"auto"}
            fontSize={"1.5rem"}
        />
    );
};
