import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { IMasterAppointmentTypeActuation } from "../../../../../../../models/masterAppointment/IAppointmentTypeActuation";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { updateMasterAppointmentTypeActuationProperty } from "../../../../../../../redux/reducers/masterAppointment/appointmentTypeActuation/form";
import { compileNameOfProperty } from "../../../../../../../utils";
import { ActuationTypesSelector } from "../../../../../../request/common/selectors/actuationTypes/actuationTypesSelector";
import { MerchandiseCategoryGroupSelector } from "../../../../../../request/common/selectors/merchandiseCategoryGrop/merchandiseCategoryGroupSelector";
import { MerchandiseTypeSelector } from "../../../../../../request/common/selectors/merchandiseType/merchandiseType";
import { AppointmentTypesSelector } from "../../../appointmentBoard/controller/edit/tabs/form/appointmentSelector/appointmentSelector";
import { MasterCertificateTypeFormStyle } from "./formStyle.jss";

export const MasterAppointmentTypeForm = () => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT" });
    const theme = useTheme();
    const style = MasterCertificateTypeFormStyle({ theme });
    const dispatch = useAppDispatch();
    const updatePropertyForm = (name: string, value: any) => {
        dispatch(updateMasterAppointmentTypeActuationProperty({ name, value }));
    };

    const { form } = useAppSelector((state) => state.masterAppointmentTypeActuationForm);
    const { control } = useForm<IMasterAppointmentTypeActuation>({ mode: "onChange" });

    const onSelectorChange = (option: any, name: string) => {
        let value = option.key;

        if (value === DefaultValues.DEFAULT_ALL_SELECTOR) {
            value = -1;
        }

        updatePropertyForm(name, value);
    };


    return (
        <div className={style.form}>
            <div className={style.rowForm}>
                <ActuationTypesSelector
                    control={control}
                    onChange={onSelectorChange}
                    selectedKey={form.actuationTypeId !== null ? form.actuationTypeId : DefaultValues.DEFAULT_ALL_SELECTOR_INTEGER}
                    propertyName={compileNameOfProperty<IMasterAppointmentTypeActuation>("actuationTypeId")}
                    title={t("APPOINTMENT_TYPES_ACTUATION.ACTUATION_TYPE")}
                    isRequired={true}
                    noneOption={true}
                    optionString={t("NONE")}
                />

                <AppointmentTypesSelector
                    title={t("APPOINTMENT_TYPES_ACTUATION.APPOINTMENT_TYPE")}
                    onChange={onSelectorChange}
                    selectedKey={form.appointmentTypeId}
                    name={compileNameOfProperty<IMasterAppointmentTypeActuation>("appointmentTypeId")}
                    isRequired={true}
                />
            </div>

            <div className={style.rowForm}>
                <MerchandiseCategoryGroupSelector
                    onChange={onSelectorChange}
                    selectedKey={form.merchandiseCategoryGroupId !== -1 ? form.merchandiseCategoryGroupId : DefaultValues.DEFAULT_ALL_SELECTOR}
                    propertyName={compileNameOfProperty<IMasterAppointmentTypeActuation>("merchandiseCategoryGroupId")}
                    title={t("APPOINTMENT_TYPES_ACTUATION.MERCHANDISE_CATEGORY")}
                    control={control}
                    allOption={true}
                />
                <MerchandiseTypeSelector
                    control={control}
                    filterOptions={false}
                    onChange={onSelectorChange}
                    selectedKey={form.merchandiseTypeId !== -1 ? form.merchandiseTypeId : DefaultValues.DEFAULT_ALL_SELECTOR}
                    propertyName={compileNameOfProperty<IMasterAppointmentTypeActuation>("merchandiseTypeId")}
                    title={t("APPOINTMENT_TYPES_ACTUATION.MERCHANDISE_TYPE")}
                    allOption={true}
                />
            </div>
        </div>
    );
};
