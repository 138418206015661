import { IDropdownOption } from "@fluentui/react";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { IGenericPayload } from "../../../../../../../models";
import { IAppointmentDatePermissionForm } from "../../../../../../../models/masterAppointment/IAppointmentDatePermission";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";

import { useContainer } from "../../../../../../../hooks/request/useContainer";
import { updateMasterAppointmentDatePermissionProperty } from "../../../../../../../redux/reducers/masterAppointment/appointmentDatePermission/form";
import { Checkboxs } from "../../../../../../common/checkbox/checkbox";
import { MultiWeekDaySelector } from "../../../../../../common/selectors/controllerSelectors/weekDaySelector/multiWeekDaySelector";
import { TimePickerComponent } from "../../../../../../common/timePicker/timePicker";
import { ContainerSizeSelector, TransportUnitSelector } from "../../../../../../request/common/selectors";
import { AppointmentDayPermissionTypesSelector } from "../../../../../../request/common/selectors/appointmentDayPermissionTypes/appointmentDayPermissionTypes";
import { MerchandiseCategoryGroupSelector } from "../../../../../../request/common/selectors/merchandiseCategoryGrop/merchandiseCategoryGroupSelector";
import { ManagementRequestDatePermisionDateFormStyle } from "./formStyle.jss";

export const ManagementAppointmentDatePermisionForm = () => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_DATE_PERMISSION" });
    const { form } = useAppSelector((state) => state.masterAppointmentDatePermissionForm);
    const dispatch = useDispatch();
    const theme = useTheme();
    const style = ManagementRequestDatePermisionDateFormStyle({ theme });
    const { control } = useForm<IAppointmentDatePermissionForm>({ mode: "onChange" });
    const isContainer = useContainer(form.transportUnitId);

    const onUpdateHeaderPopperty = (name: string, value: any) => {
        let payload: IGenericPayload = {
            name,
            value,
        };
        dispatch(updateMasterAppointmentDatePermissionProperty(payload));
    };

    const onSelectorChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateHeaderPopperty(propertyName, option.key);
    };

    const onStartTimeChange = useCallback(
        (date: MaterialUiPickersDate) => {
            date &&
                onUpdateHeaderPopperty(compileNameOfProperty<IAppointmentDatePermissionForm>("startTime"), new Date(date.toISOString()).toString());
        },
        [dispatch],
    );
    const onEndTimeChange = useCallback(
        (date: MaterialUiPickersDate) => {
            date && onUpdateHeaderPopperty(compileNameOfProperty<IAppointmentDatePermissionForm>("endTime"), new Date(date.toISOString()).toString());
        },
        [dispatch],
    );

    const onCheckboxChange = useCallback(
        (event?: any, checked = false) => {
            onUpdateHeaderPopperty(event.target.name, checked);
        },
        [dispatch],
    );

    const onMultipleNumberKeysChange = useCallback(
        (name: string, numberOptions: number[]) => {
            onUpdateHeaderPopperty(name, numberOptions);
        },
        [dispatch],
    );

    useEffect(() => {
        if (!isContainer && form.transportUnitId !== 0) {
            let payload: IGenericPayload = {
                name: compileNameOfProperty<IAppointmentDatePermissionForm>("transportUnitSizeId"),
                value: 0,
            };
            dispatch(updateMasterAppointmentDatePermissionProperty(payload));
        }
    }, [isContainer, form.transportUnitId]);

    return (
        <div className={style.form}>
            <div className={style.rowForm}>
                <TransportUnitSelector
                    onChange={onSelectorChange}
                    selectedKey={form.transportUnitId}
                    propertyName={compileNameOfProperty<IAppointmentDatePermissionForm>("transportUnitId")}
                    title={t("TRANSPORT_UNIT")}
                    control={control}
                    isRequired={false}
                    rules={{ required: false }}
                />
                <ContainerSizeSelector
                    onChange={onSelectorChange}
                    selectedKey={form.transportUnitSizeId ?? 0}
                    propertyName={compileNameOfProperty<IAppointmentDatePermissionForm>("transportUnitSizeId")}
                    disabled={!isContainer}
                    title={t("TRANSPORT_SIZE")}
                    isRequired={isContainer}
                    control={control}
                    rules={{ required: true }}
                />
            </div>
            <div className={style.rowForm}>
                <MerchandiseCategoryGroupSelector
                    onChange={onSelectorChange}
                    selectedKey={form.merchandiseCategoryGroupId}
                    propertyName={compileNameOfProperty<IAppointmentDatePermissionForm>("merchandiseCategoryGroupId")}
                    title={t("MERCHANDISE_CATEGORY")}
                    control={control}
                    isRequired={true}
                />

                <AppointmentDayPermissionTypesSelector
                    onChange={onSelectorChange}
                    selectedKey={form.referenceDayId}
                    propertyName={compileNameOfProperty<IAppointmentDatePermissionForm>("referenceDayId")}
                    title={t("DAY")}
                    isRequired={true}
                    control={control}
                    rules={{ required: true }}
                />
            </div>
            <div className={style.smallRowForm}>
                <span className={style.timeComponent}>
                    <TimePickerComponent
                        onChange={onStartTimeChange}
                        value={form.startTime ? new Date(form.startTime) : null}
                        label={t("START_TIME")}
                        required={true}
                        isCreate={true}
                    />
                </span>
                <span className={style.timeComponent}>
                    <TimePickerComponent
                        onChange={onEndTimeChange}
                        value={form.endTime ? new Date(form.endTime) : null}
                        label={t("END_TIME")}
                        required={true}
                        isCreate={true}
                    />
                </span>
            </div>
            <div className={style.smallRowForm}>
                <Checkboxs
                    label={t("WORK_DAY")}
                    name={compileNameOfProperty<IAppointmentDatePermissionForm>("workDays")}
                    isChecked={form.workDays}
                    handleChange={onCheckboxChange}
                />
                <Checkboxs
                    label={t("MANUAL")}
                    name={compileNameOfProperty<IAppointmentDatePermissionForm>("manual")}
                    isChecked={form.manual}
                    handleChange={onCheckboxChange}
                />
            </div>
            <div className={style.rowForm}>
                <MultiWeekDaySelector
                    onChange={onMultipleNumberKeysChange}
                    selectedKeys={form.weekDays}
                    propertyName={compileNameOfProperty<IAppointmentDatePermissionForm>("weekDays")}
                    title={t("APLICATION_DAYS")}
                    autocomplete={false}
                />
            </div>
        </div>
    );
};
