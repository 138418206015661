import { useTranslation } from "react-i18next";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { FormEvent, useEffect, useMemo } from "react";
import { MasterSelector } from "../../common/selectors/controllerSelectors/masterSelector/masterSelector";
import { Separator } from "../../common/separator/separator";
import { fetchRoleOptions } from "../../../redux/actions/catalog/catalog";
import { ManagementMenuSettingsByRolList } from "./list/list";
import { useTheme } from "react-jss";
import { ManagementMenuSettingsByRolContainerStyle } from "./containerStyle.jss";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { useSelector } from "react-redux";
import { getUserRole } from "../../../redux/selectors/user/role/role";
import { getRolesFiltered } from "../../../common/utils/filterUserRole";
import {
    setManagementMenuSettingsByRoleRole,
    setManagementMenuSettingsByRoleRolePopUpConfirmation,
} from "../../../redux/reducers/management/menuSettingsByRole/form";
import { Skeleton } from "../../common/skeleton/skeleton";
import { SkeletonSizes } from "../../../common/constants";
import {
    fetchManagementMenuSettingsListAction,
    postAndPutManagementMenuSettingsAction,
} from "../../../redux/actions/management/menuSettings/menuSettings";
import { PopUpConfirmation } from "../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpConfirmationType } from "../../../common/enum/PopUpConfirmationType";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";

export const ManagementMenuSettingsByRolContainer = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");
    const theme = useTheme();
    const style = ManagementMenuSettingsByRolContainerStyle({ theme });
    const userRole = useSelector(getUserRole);
    const { roleId, loading, form, showPopUp } = useAppSelector((store) => store.managementMenuSettingsForm);
    const rolesUser = useAppSelector((store) => store.role.roleData.roleManagement);
    const roles = useAppSelector((store) => store.catalog.roles.data);

    useEffect(() => {
        if (roles === undefined) {
            dispatch(fetchRoleOptions());
        }

        if (!roleId) {
            dispatch(setManagementMenuSettingsByRoleRole(userRole.id));
        }

        if (form === undefined) {
            dispatch(fetchManagementMenuSettingsListAction());
        }
    }, [userRole, roles, form]);

    const masterSelectorOptions = useMemo(() => {
        return getRolesFiltered(roles ?? [], rolesUser).map((item) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }, [rolesUser, roles]);

    const roleDescription = useMemo(() => {
        let role = masterSelectorOptions.find((item) => item.key === roleId);
        return role?.text;
    }, [roleId, masterSelectorOptions]);

    const hasActiveFeature = useMemo(() => {
        return form?.some((feature) => feature.features.some((item) => item.active));
    }, [form]);

 
    

    return (
        <div className={style.container}>
            <NavHeaderComponent
                title={t("MANAGEMENT.MENU_SETTINGS.TITLE")}
                action={
                    <>
                        <Separator></Separator>
                        {!roleId ? (
                            <Skeleton rows={1}></Skeleton>
                        ) : (
                            <MasterSelector
                                onChange={(_event: FormEvent<HTMLDivElement>, option: any, name: string) => {
                                    dispatch(setManagementMenuSettingsByRoleRole(option.key));
                                    dispatch(fetchManagementMenuSettingsListAction());
                                }}
                                options={masterSelectorOptions}
                                selectedKey={roleId}
                                isDisabled={loading}
                            />
                        )}
                    </>
                }
            />

            <div className={style.containerList}>{loading ? <Skeleton rows={SkeletonSizes.DEFAULT} /> : <ManagementMenuSettingsByRolList />}</div>

            <div className={style.actions}>
                <ButtonPrimary
                    disabled={loading || !hasActiveFeature }
                    title={t("COMMON.SAVE")}
                    handleClick={() => { 
                        dispatch(setManagementMenuSettingsByRoleRolePopUpConfirmation(PopUpCodes.WARNING));
                    }}
                />
            </div>
            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.WARNING}
                message={t("MANAGEMENT.MENU_SETTINGS.WARNING", { roleId: roleDescription })}
                onCancel={() => {
                    dispatch(setManagementMenuSettingsByRoleRolePopUpConfirmation(undefined as any));
                }}
                onAccept={() => {
                    dispatch(postAndPutManagementMenuSettingsAction()).then((response: any) => {
                        if (response) {
                            dispatch(setManagementMenuSettingsByRoleRolePopUpConfirmation(undefined as any));
                        }
                    });
                }}
                height={"auto"}
                width={"auto"}
                type={PopUpConfirmationType.INFO}
            ></PopUpConfirmation>
        </div>
    );
};
