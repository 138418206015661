import { Icon, IDropdownOption, IPivotItemProps, IPivotStyles, IStyleSet, Pivot, PivotItem, TooltipHost } from "@fluentui/react";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { EditRequestValidation } from "../../../../../common/constants/editRequestValidation";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { IGenericPayload } from "../../../../../models";
import { IConfigEditRequest } from "../../../../../models/requests/IConfigEditRequest";
import { IEditRequest } from "../../../../../models/requests/IEditRequest";
import { updateRequestFormData } from "../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
    setCertificatesFormValid,
    setGoodInfoFormValid,
    setIndexTabRequest,
    setUtiFormValid,
} from "../../../../../redux/reducers/request/edit/editRequest";
import {
    compileNameOfProperty,
    convertToLocal,
    getBeforeOfToday,
    getGenericPayloadFromEvent,
    getPivotGeneralInformationStyles,
} from "../../../../../utils";
import { FormatNumberInput } from "../../../../common/numberField/utils";
import { GeneralInformationEditContainerStyle } from "./containerStyle.jss";
import { GoodInfoData } from "./goodInformation/goodInfoData";
import { EditRequestGeneralInformationEditRequestInspections } from "./inspection/container";
import { MerchandiseData } from "./merchandise/merchandise";
import { UtiData } from "./uti/utiData";
import { RequestState } from "../../../../../common/enum/request/RequestState";

interface IProps {
    schema: IConfigEditRequest;
    currentRole: string;
    onUpdate: () => void;
}

export const GeneralInformationEditContainer: React.FC<IProps> = ({ schema, currentRole, onUpdate }) => {
    const theme = useTheme() as any;
    const styles = GeneralInformationEditContainerStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const {
        userData: {
            profile: { organizations },
        },
        organization: {
            organization: { id: currentOrganization },
        },
        editRequest: { indexTabRequest },
        catalog: {
            transportUnitSizes: { data: transportUnitSizesData },
        },

    } = useAppSelector((state) => state);

    const dispatch = useAppDispatch();
    const [selectedKey, setSelectedKey] = React.useState<string>(String(indexTabRequest));

    const pivotStyles = useMemo<Partial<IStyleSet<IPivotStyles>>>(() => getPivotGeneralInformationStyles(theme), [theme]);
    const {
        headerRequest,
        canAssignAppointment,
        isLoadedRequest,
        copyHeaderRequest,
        form: { utiForm, goodInfoForm, certificatesForm },
    } = useAppSelector((store) => store.editRequest);

    const organization = useMemo(() => {
        return organizations.find((item) => item.id === currentOrganization);
    }, [organizations, currentOrganization]);

    const {
        register: registrerUti,
        control: controlUti,
        setValue: setValueUti,
        trigger: triggerUti,
        resetField: resetFieldUti,
        setError: setErrorUti,
        clearErrors: clearErrorsUti,
        unregister: unregisterUti,
        formState: { errors: errorUti, isValid: isValidUti },
    } = useForm<IEditRequest>();
    const {
        register: registrerGoodInfo,
        control: controlGoodInfo,
        trigger: triggerGoodInfo,
        setValue: setValueGoodInfo,
        clearErrors: clearErrorsGoodInfo,
        unregister: unregisterGoodInfo,
        formState: { errors: errorGoodInfo, isValid: isValidGoodInfo },
    } = useForm<IEditRequest>();

    const {
        register: registrerCertificates,
        control: controlCertificates,
        trigger: triggerCertificates,
        setValue: setValueCertificates,
        clearErrors: clearErrorsCertificate,
        unregister: unregisterCertificates,

        formState: { errors: errorsCertificates, isValid: isValidCertificates },
    } = useForm<IEditRequest>();

    useEffect(() => {
        if (headerRequest.stateId === RequestState.CANCELED) {
            clearErrorsUti();
            unregisterUti();
            triggerUti();
            clearErrorsGoodInfo();
            unregisterGoodInfo();
            triggerGoodInfo();
            clearErrorsCertificate();
            unregisterCertificates();
            triggerCertificates();
        }
    }, [headerRequest.stateId, headerRequest.customerId, headerRequest.invoiceCustomerId]);

    const updateRequestHeader = (propertyName: string, value: any) => {
        const payload: IGenericPayload = {
            name: propertyName,
            value,
        };

        dispatch(updateRequestFormData(payload));
    };

    const onChangeEventTrigger = (propertyName: string) => {
        switch (propertyName) {
            case "merchandiseOriginId":
            case "humanConsumption":
            case "merchandiseTypeId": {
                updateRequestHeader("dockId", null);
                break;
            }
        }
    };

    const onUpdateRequestHeaderProperty = (propertyName: string, value: any) => {
        updateRequestHeader(propertyName, value);
        onChangeEventTrigger(propertyName);
    };

    const onSelectorChange = (option: IDropdownOption, propertyName: string, optionalParam?: any) => {
        type ObjectKey = keyof typeof headerRequest;
        const property = propertyName as ObjectKey;

        if (propertyName === compileNameOfProperty<IEditRequest>("transportUnitId")) {
            setValueUti("transportUnitNumber", "");
            setValueUti("transportUnitSizeId", undefined);
            setValueGoodInfo("terminalId", undefined);
            onUpdateRequestHeaderProperty(compileNameOfProperty<IEditRequest>("transportUnitNumber"), "");
            onUpdateRequestHeaderProperty(compileNameOfProperty<IEditRequest>("terminalId"), null);
            onUpdateRequestHeaderProperty(compileNameOfProperty<IEditRequest>("transportUnitSizeId"), null);
        }

        if (optionalParam) {
            onUpdateRequestHeaderProperty(compileNameOfProperty<IEditRequest>("representativeId"), optionalParam);
        }

        if (headerRequest[property] !== option.key) {
            onUpdateRequestHeaderProperty(propertyName, option.key);
        }
    };

    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateRequestFormData(payload));
        },
        [dispatch],
    );

    const resetPropertyWhenParentChange = (propertyName: string) => {
        onUpdateRequestHeaderProperty(propertyName, null);
    };

    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        type ObjectKey = keyof typeof headerRequest;
        const property = propertyName as ObjectKey;

        if (headerRequest[property] !== !!+option.key) {
            onUpdateRequestHeaderProperty(propertyName, !!+option.key);
        }
    };

    const onCheckboxChange = useCallback(
        (event?: any, checked = false) => {
            onUpdateRequestHeaderProperty(event.target.name, checked);
        },
        [dispatch],
    );

    const onRadioButtonChange = useCallback(
        (optionKey: string, propertyName: string) => {
            onUpdateRequestHeaderProperty(propertyName, !!+optionKey);
        },
        [dispatch],
    );
    const onNumberChange = useCallback(
        (event: any, _newValue?: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateRequestHeaderProperty(name, FormatNumberInput(value));
        },
        [dispatch],
    );
    const onToggleChange = useCallback(
        (event: any, checked?: boolean | undefined, name?: string) => {
            onUpdateRequestHeaderProperty(name ?? "", checked != undefined ? checked : false);
        },
        [dispatch],
    );

    const customRenderer =
        (isValid: boolean) =>
        (link?: IPivotItemProps, defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null): JSX.Element | null => {
            if (!link || !defaultRenderer) {
                return null;
            }
            return (
                <span style={{ display: "flex" }}>
                    {defaultRenderer({ ...link, itemIcon: undefined })}
                    {!isValid && utiForm && goodInfoForm && certificatesForm && headerRequest.stateId !== RequestState.CANCELED && (
                        <TooltipHost
                            content={t("INFO.TOOLTIP_PENDING_FIELDS_REQUIRED")}
                            children={
                                <Icon
                                    iconName={link.itemIcon}
                                    style={{ color: "red", marginLeft: 8, fontSize: 6, display: "flex" }}
                                />
                            }
                        />
                    )}
                </span>
            );
        };

    const triggerFormValidityChecks = useCallback(() => {
        triggerUti(EditRequestValidation.UTI as any[]);
        triggerGoodInfo(EditRequestValidation.GOOD_INFO as any);
        triggerCertificates(EditRequestValidation.CERTIFICATES as any);
    }, []);

    const disabledAppointmentData = useMemo(() => {
        if (headerRequest.appointmentAssigned && (!schema.appointmentCreate.visible || getBeforeOfToday(headerRequest.date))) {
            return true;
        }
        return false;
    }, [headerRequest, schema]);

    const isValidAppointment = useMemo(() => {
        return headerRequest.appointmentAssigned && headerRequest.appointment === null && canAssignAppointment ? false : true;
    }, [headerRequest, canAssignAppointment]);

    const errorMinDate = () => {
        return t("INFO.INVALID_INSPECTION_TIME", {
            dockStartOn: convertToLocal(headerRequest.dockStartOn, FormatDate.DATE_HOUR_MINUTE),
            interpolation: { escapeValue: false },
            timeWithOrganization: "",
        });
    };
    const errorMaxDate = () => {
        return t("INFO.MAX_DOCK_EVENT", {
            dockEndOn: convertToLocal(headerRequest.dockEndOn, FormatDate.DATE_HOUR_MINUTE),
            interpolation: { escapeValue: false },
        });
    };

    const validateDate = (date: Date) => {
        date.setSeconds(0);
        date.setMilliseconds(0);
        if (!headerRequest.dockStartOn) {
            return t("INFO.NOT_DOCK_ENTRY_ON");
        }
        if (headerRequest.dockEndOn && date > headerRequest.dockEndOn) {
            return errorMaxDate();
        }

        return headerRequest.dockStartOn <= date ? "" : errorMinDate();
    };

    const validCustomsAuthorityArrivalOn = useMemo(() => {
        if (
            schema.customsAuthorityArrivalOn.visible &&
            organization?.events?.customsAuthorityArrival.active &&
            headerRequest.customsAuthorityArrivalOn
        ) {
            return validateDate(headerRequest.customsAuthorityArrivalOn);
        }
        return "";
    }, [schema, organization, headerRequest.dockStartOn, headerRequest.customsAuthorityArrivalOn, headerRequest.dockEndOn]);

    const validRepresentativeArrivalOn = useMemo(() => {
        if (schema.representativeArrivalOn.visible && organization?.events?.representativeArrival.active && headerRequest.representativeArrivalOn) {
            return validateDate(headerRequest.representativeArrivalOn);
        }
        return "";
    }, [schema, organization, headerRequest.dockStartOn, headerRequest.representativeArrivalOn, headerRequest.dockEndOn]);

    const validReadyForInstructionOn = useMemo(() => {
        if (schema.readyForInstructionOn.visible && organization?.events?.readyForInstruction.active && headerRequest.readyForInstructionOn) {
            return validateDate(headerRequest.readyForInstructionOn);
        }
        return "";
    }, [schema, organization, headerRequest.dockStartOn, headerRequest.readyForInstructionOn, headerRequest.dockEndOn]);

    const invalidRequestForm = useMemo(
        () => !isValidUti || !isValidGoodInfo || !isValidCertificates,
        [isValidUti, isValidGoodInfo, isValidCertificates],
    );

    const pivotUtiComponent = useMemo(() => {
        return (
            <>
                <UtiData
                    schema={schema}
                    onInputChange={onInputChange}
                    onSelectorChange={onSelectorChange}
                    resetPropertyWhenParentChange={resetPropertyWhenParentChange}
                    onDateChange={() => {}}
                    onYesNoOptionsChange={onYesNoOptionsChange}
                    onCheckBoxChange={onCheckboxChange}
                    onRadioButtonChange={onRadioButtonChange}
                    onNumberChange={onNumberChange}
                    register={registrerUti}
                    control={controlUti}
                    setValue={setValueUti}
                    trigger={triggerUti}
                    errors={errorUti}
                    resetField={resetFieldUti}
                    setError={setErrorUti}
                    organization={organization}
                    currentRole={currentRole}
                    disabledAppointmentData={disabledAppointmentData}
                    validcustomsAuthorityArrivalOn={validCustomsAuthorityArrivalOn}
                    validrepresentativeArrivalOn={validRepresentativeArrivalOn}
                    validreadyForInstructionOn={validReadyForInstructionOn}
                    invalidRequestForm={invalidRequestForm}
                />
            </>
        );
    }, [
        headerRequest,
        errorUti,
        isValidUti,
        currentRole,
        validCustomsAuthorityArrivalOn,
        validRepresentativeArrivalOn,
        validReadyForInstructionOn,
        invalidRequestForm,
        transportUnitSizesData
    ]);

    const pivotGoodData = useMemo(() => {
        return (
            <GoodInfoData
                disabledAppointmentData={disabledAppointmentData}
                schema={schema}
                onInputChange={onInputChange}
                onSelectorChange={onSelectorChange}
                resetPropertyWhenParentChange={resetPropertyWhenParentChange}
                onDateChange={() => {}}
                onYesNoOptionsChange={onYesNoOptionsChange}
                onCheckBoxChange={onCheckboxChange}
                onRadioButtonChange={onRadioButtonChange}
                onNumberChange={onNumberChange}
                register={registrerGoodInfo}
                control={controlGoodInfo}
                setValue={setValueGoodInfo}
                trigger={triggerGoodInfo}
                errors={errorGoodInfo}
                organization={organization}
                invalidRequestForm={false}
            />
        );
    }, [errorGoodInfo, headerRequest, isValidGoodInfo]);

    const pivotCertificateData = useMemo(() => {
        return (
            <MerchandiseData
                disabledAppointmentData={disabledAppointmentData}
                schema={schema}
                onCheckBoxChange={onCheckboxChange}
                onInputChange={onInputChange}
                onSelectorChange={onSelectorChange}
                resetPropertyWhenParentChange={resetPropertyWhenParentChange}
                onDateChange={() => {}}
                onYesNoOptionsChange={onYesNoOptionsChange}
                onRadioButtonChange={onRadioButtonChange}
                onNumberChange={onNumberChange}
                register={registrerCertificates}
                control={controlCertificates}
                setValue={setValueCertificates}
                trigger={triggerCertificates}
                errors={errorsCertificates}
                organization={organization}
                onToggleChange={onToggleChange}
                invalidRequestForm={false}
                onUpdateRequest={onUpdate}
            />
        );
    }, [errorsCertificates, headerRequest, isValidCertificates]);

    useEffect(() => {
        if (isLoadedRequest) {
            triggerFormValidityChecks();
            dispatch(setUtiFormValid(true));
            dispatch(setGoodInfoFormValid(true));
            dispatch(setCertificatesFormValid(true));
        }
    }, [isLoadedRequest, headerRequest]);
    const copy = useMemo(() => {
        let tempCopy: IEditRequest = { ...copyHeaderRequest };
        delete tempCopy.showCustomerNotificationMessage;
        return tempCopy;
    }, [copyHeaderRequest]);

    const edit = useMemo(() => {
        let tempEdit: IEditRequest = { ...headerRequest };
        delete tempEdit.showCustomerNotificationMessage;
        return tempEdit;
    }, [headerRequest]);

    const disabledActuationButton = useMemo(() => {
        return (!isValidUti || !isValidGoodInfo || !isValidCertificates || !isEqual(copy, edit)) && organization?.preappointment ? true : false;
    }, [isValidUti, isValidGoodInfo, isValidCertificates, organization, copy, edit]);

    return (
        <>
            <Pivot
                className={styles.pivotContainer}
                styles={pivotStyles}
                selectedKey={String(selectedKey)}
                onLinkClick={(event) => {
                    const index: string = event?.props.itemKey ? event?.props.itemKey : "0";
                    setSelectedKey(index);
                    dispatch(setIndexTabRequest(+index));
                }}
            >
                {schema.uti.visible && (
                    <PivotItem
                        headerText={t("TITLES.UTI")}
                        itemIcon="CircleFill"
                        itemKey="0"
                        onRenderItemLink={
                            isLoadedRequest
                                ? customRenderer(
                                      isValidUti &&
                                          validCustomsAuthorityArrivalOn === "" &&
                                          validRepresentativeArrivalOn === "" &&
                                          validReadyForInstructionOn === "" &&
                                          isValidAppointment,
                                  )
                                : undefined
                        }
                        alwaysRender={true}
                    >
                        {pivotUtiComponent}
                    </PivotItem>
                )}

                {schema.goodInformation.visible && (
                    <PivotItem
                        headerText={t("TITLES.INFORMATION")}
                        itemIcon="CircleFill"
                        itemKey="1"
                        onRenderItemLink={isLoadedRequest ? customRenderer(isValidGoodInfo) : undefined}
                        alwaysRender={true}
                    >
                        {pivotGoodData}
                    </PivotItem>
                )}

                {schema.certificate.visible && (
                    <PivotItem
                        headerText={t("TITLES.LOADDATA")}
                        itemKey="2"
                        itemIcon="CircleFill"
                        onRenderItemLink={isLoadedRequest ? customRenderer(isValidCertificates) : undefined}
                        alwaysRender={true}
                    >
                        {pivotCertificateData}
                    </PivotItem>
                )}
                {schema.inspection.visible && (
                    <PivotItem
                        headerText={t("TITLES.CERTIFICATES")}
                        itemKey="3"
                    >
                        <EditRequestGeneralInformationEditRequestInspections
                            schema={schema}
                            invalidRequest={disabledActuationButton}
                        />
                    </PivotItem>
                )}
            </Pivot>
        </>
    );
};
