export enum ActuationTypes {
    PENDING = "PD",
    DO_NOT_POSITION = "DO",
    INSPECTION = "IN",
    SEE_SEAL = "VP",
    HALLWAY = "PA",
    DOWNLOAD_COMPLETE = "DC",
    NOT_APPLICABLE = "NC",
    SAMPLING = "TM",
}
