export enum DefaultValues {
    EMPTY = "--",
    EMPTY_LIST = "-",
    DEFAULT_ID = 0,
    NOT_DATA = 0,
    DEFAULT_ALL_SELECTOR = "-1",
    DEFAULT_ALL_SELECTOR_INTEGER = -1,
    NOT_TEXT = "",
    EURO = "€",
}
