import { FormatDate } from "../../common/enum/dateTime/FormatDate";
import { Method } from "../../common/enum";
import { IAppointmentAssign } from "../../models/appointment/IAppointmentAssign";
import { IAppointmentBoard } from "../../models/appointment/IBoard";
import { IQueryZoneAvaiable, IZoneAvaiable } from "../../models/appointment/IDashboardZonesAvaiable";
import { IAppointmentDatePermission, IAppointmentDatePermissionForm } from "../../models/masterAppointment/IAppointmentDatePermission";
import { IAppointmentPermission } from "../../models/masterAppointment/IAppointmentPermission";
import { IMasterAppointmentType } from "../../models/masterAppointment/IAppointmentType";
import { IMasterAppointmentTypeActuation } from "../../models/masterAppointment/IAppointmentTypeActuation";
import { IUpcomingAppointment, IUpcomingAppointmentFilter } from "../../models/upcomingAppointment/IUpcomingAppointments";
import { IUpcomingAppointmentSortFilter, IUpcomingAppointmentSortItem } from "../../models/upcomingAppointmentSort/IUpcomingAppointmentSort";
import { apiFetchOAuthWithClaims } from "../../services/apiClient";
import { convertToLocal } from "../../utils";
import { objectToQueryString } from "../../utils/objectUtils";
import { DefaultValues } from "../../common/enum/DefaultValues";
import { ICanAssignAppointment, ICheckAssignAppointmentRequest } from "../../models/requests/editRequest/IAppointment";
import { IGenericImportFinishedData } from "../../models/common/IImportGeneric";
import { IAppointmentImportForm } from "../../redux/reducers/appointment/appoitmentImport/form";

const baseURL = `${process.env.REACT_APP_API_URL}/appointment`;
export class AppointmentsAPI {
    async getAppointmentsList(filter: IUpcomingAppointmentFilter): Promise<IUpcomingAppointment[]> {
        const url = `${baseURL}/upcomings`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(mapFilters(filter)).execute<IUpcomingAppointment[]>();
    }
    async getAppointmentsUpcomingsSortList(filter: IUpcomingAppointmentFilter): Promise<IUpcomingAppointment[]> {
        const url = `${baseURL}/upcomingssort`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(mapFilters(filter)).execute<IUpcomingAppointment[]>();
    }

    async getMasterAppointmentTypeList(): Promise<IMasterAppointmentType[]> {
        const url = `${baseURL}/types/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterAppointmentType[]>();
    }
    async getMasterAppointmentTypeRemaining(): Promise<IMasterAppointmentType[]> {
        const url = `${baseURL}/master/types/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterAppointmentType[]>();
    }
    async getCatalogAppointmentType(): Promise<IMasterAppointmentType[]> {
        const url = `${baseURL}/types`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterAppointmentType[]>();
    }
    async getMasterAppointmentTypeForm(id: number): Promise<IMasterAppointmentType> {
        const url = `${baseURL}/types/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterAppointmentType>();
    }

    async postMasterAppointmentTypeForm(form: IMasterAppointmentType): Promise<boolean> {
        const url = `${baseURL}/types`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterAppointmentTypeForm(form: IMasterAppointmentType): Promise<boolean> {
        const url = `${baseURL}/types/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getMasterAppointmentDatePermissionList(): Promise<IAppointmentDatePermission[]> {
        const url = `${baseURL}/datepermissions`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IAppointmentDatePermission[]>();
    }

    async getMasterAppointmentDatePermissionForm(id: number): Promise<IAppointmentDatePermissionForm> {
        const url = `${baseURL}/datepermissions/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IAppointmentDatePermissionForm>();
    }
    async removeMasterAppointmentDatePermissionForm(id: number): Promise<boolean> {
        const url = `${baseURL}/datepermissions/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }

    async postMasterAppointmentDatePermissionForm(form: any): Promise<boolean> {
        const url = `${baseURL}/datepermissions`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterAppointmentDatePermissionForm(form: any): Promise<boolean> {
        const url = `${baseURL}/datepermissions/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getMasterAppointmentTypeActuationList(): Promise<IMasterAppointmentTypeActuation[]> {
        const url = `${baseURL}/typeactuations/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterAppointmentTypeActuation[]>();
    }

    async getMasterAppointmentTypeActuationForm(id: number): Promise<IMasterAppointmentTypeActuation> {
        const url = `${baseURL}/typeactuations/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterAppointmentTypeActuation>();
    }

    async postMasterAppointmentTypeActuationForm(form: any): Promise<boolean> {
        const url = `${baseURL}/typeactuations`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async removeMasterAppointmentTypeActuationForm(id: number): Promise<boolean> {
        const url = `${baseURL}/typeactuations/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }

    async putMasterAppointmentTypeActuationForm(form: any): Promise<boolean> {
        const url = `${baseURL}/typeactuations/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async managementSort(tableName: string, id: number, sortType: string): Promise<boolean> {
        const url = `${process.env.REACT_APP_API_URL}/management/sort/${tableName}/${id}/${sortType}`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).execute<boolean>();
    }

    async appointmentBoard(date: string): Promise<IAppointmentBoard[]> {
        const url = `${baseURL}/board?date=${date}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IAppointmentBoard[]>();
    }

    async appointmentBoardTimeZoneLock(date: string, appointmentDockId: number, appointmentTimeZoneId: number): Promise<boolean> {
        const url = `${baseURL}/dashboards/docktimezonelocks`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody({ date, appointmentDockId, appointmentTimeZoneId }).execute<boolean>();
    }

    async deleteAppointmentBoardTimeZoneLock(id: number): Promise<boolean> {
        const url = `${baseURL}/dashboards/docktimezones/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }

    async getImportTemplate(): Promise<any> {
        const url = `${baseURL}/customers/import/template`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<any>().then();
    }

    async getZonesAvailables(zoneAvailables: IQueryZoneAvaiable): Promise<IZoneAvaiable> {
        if (zoneAvailables.transportUnitSizeId === 0) {
            delete zoneAvailables.transportUnitSizeId;
        }
        const queryParams = objectToQueryString(zoneAvailables);
        const url = `${baseURL}/dashboards/zones/availables?${queryParams}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IZoneAvaiable>();
    }

    async postAppointmentAssign(appointmentAssign: IAppointmentAssign): Promise<any> {
        const url = `${baseURL}/assign`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(appointmentAssign).execute<any>();
    }

    async getUpcomingAppointmentsSortList(filter: IUpcomingAppointmentSortFilter): Promise<IUpcomingAppointmentSortItem[]> {
        const url = `${baseURL}/upcomingssort`;
        return apiFetchOAuthWithClaims(Method.POST, url)
            .withBody(mapUpcomingAppointmentsSortFilters(filter))
            .execute<IUpcomingAppointmentSortItem[]>();
    }

    async getExportUpcomingAppointmentsSortList(filter: IUpcomingAppointmentSortFilter): Promise<string> {
        const url = `${baseURL}/upcomingssort/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(mapUpcomingAppointmentsSortFilters(filter)).execute<string>();
    }
    async getExportAppointmentsList(filter: IUpcomingAppointmentFilter): Promise<string> {
        const url = `${baseURL}/upcomings/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(mapFilters(filter)).execute<string>();
    }

    async getMasterAppointmentPermissionList(): Promise<IAppointmentPermission[]> {
        const url = `${baseURL}/permissions/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IAppointmentPermission[]>();
    }

    async getMasterAppointmentPermissionForm(id: number): Promise<IAppointmentPermission> {
        const url = `${baseURL}/permissions/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IAppointmentPermission>();
    }
    async removeMasterAppointmentPermissionForm(id: number): Promise<boolean> {
        const url = `${baseURL}/permissions/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }

    async postMasterAppointmentPermissionForm(form: any): Promise<boolean> {
        const url = `${baseURL}/permissions`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterAppointmentPermissionForm(form: any): Promise<boolean> {
        const url = `${baseURL}/permissions/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getAppointmentPermission(form: ICanAssignAppointment): Promise<boolean> {
        const url = `${baseURL}/permissions?transportUnitId=${form.transportUnitId}${
            form.transportUnitSizeId ? `&transportUnitSizeId=${form.transportUnitSizeId}` : ""
        }${form.merchandiseCategoryId ? `&merchandiseCategoryId=${form.merchandiseCategoryId}` : ""}&date=${form.date}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody(form).execute<boolean>();
    }

    async removeAppointmentRequest(requestId: string): Promise<boolean> {
        const url = `${baseURL}/delete/${requestId}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }

    async checkAssignAppointmentRequest(form: ICheckAssignAppointmentRequest): Promise<{ date: Date; message: string }> {
        const url = `${baseURL}/assign/check`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<{ date: Date; message: string }>();
    }

    async importAppointment(form: IAppointmentImportForm): Promise<IGenericImportFinishedData> {
        const url = `${baseURL}/import`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(mapImportAppointmentForm(form, "json")).execute<IGenericImportFinishedData>();
    }

    async importAppointmentDocument(form: IAppointmentImportForm): Promise<IGenericImportFinishedData> {
        const url = `${baseURL}/import/document`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(mapImportAppointmentForm(form, "document")).execute<IGenericImportFinishedData>();
    }

    async importAppointmentText(form: IAppointmentImportForm): Promise<IGenericImportFinishedData> {
        const url = `${baseURL}/import/text`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(mapImportAppointmentForm(form, "text")).execute<IGenericImportFinishedData>();
    }
}

const mapImportAppointmentForm = (form: IAppointmentImportForm, param: string) => {
    const formData = new FormData();
    formData.append(param, form.document);
    formData.append("typeCode", form.typeCode);

    return formData;
};

export const mapUpcomingAppointmentsSortFilters = (filter: IUpcomingAppointmentSortFilter) => {
    return {
        filters: {
            startDate: convertToLocal(filter.startDate, FormatDate.ISO),
            endDate: convertToLocal(filter.endDate, FormatDate.ISO),
            customerId: filter.customerId !== DefaultValues.DEFAULT_ALL_SELECTOR ? filter.customerId : null,
            inspectorId: filter.inspectorId !== DefaultValues.DEFAULT_ALL_SELECTOR ? filter.inspectorId : null,
            organismId: filter.organismId !== DefaultValues.DEFAULT_ALL_SELECTOR ? Number(filter.organismId) : null,
        },
    };
};

export const mapFilters = (filter: IUpcomingAppointmentFilter) => {
    return {
        filters: {
            startDate: convertToLocal(filter.startDate, FormatDate.ISO),
            endDate: convertToLocal(filter.endDate, FormatDate.ISO),
            customerId: filter.customerId !== DefaultValues.DEFAULT_ALL_SELECTOR ? filter.customerId : null,
            inspectorId: filter.inspectorId !== DefaultValues.DEFAULT_ALL_SELECTOR ? filter.inspectorId : null,
            merchandiseCategoryId: filter.merchandiseCategoryId !== DefaultValues.DEFAULT_ALL_SELECTOR ? Number(filter.merchandiseCategoryId) : null,
            organismId: filter.organismId !== DefaultValues.DEFAULT_ALL_SELECTOR ? Number(filter.organismId) : null,
            merchandiseTypeId: filter.merchandiseTypeId !== DefaultValues.DEFAULT_ALL_SELECTOR ? Number(filter.merchandiseTypeId) : null,
        },
    };
};
