import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useParams } from "react-router";
import { Milliseconds } from "../../../common/enum";
import { IDockDisplay } from "../../../models/management/dockDisplay/IDockDisplay";
import { fetchManagementDockDisplayAction } from "../../../redux/actions/management/dockDisplay/dockDisplay";
import { ManagementDockLedDisplayAssignedVehicles } from "./assigned/assigned";
import { ManagementDockLedDisplayContainerStyle } from "./containerStyle.jss";
import { ManagementDockLedDisplayWaitingVehicles } from "./waiting/waiting";

export const ManagementDockLedDisplayContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.DOCK_DISPLAY" });
    const { organization } = useParams();
    const theme = useTheme();
    const style = ManagementDockLedDisplayContainerStyle({ theme });
    const [dockDisplay, setDockDisplay] = useState<IDockDisplay | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setDockDisplay(null);
                const result = await fetchManagementDockDisplayAction(organization);
                setDockDisplay(result);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, Milliseconds.TWENTY_SEC);
        return () => clearInterval(intervalId);
    }, [organization]);

    const RenderWaitingVehicles = useMemo(() => <ManagementDockLedDisplayWaitingVehicles displayWaiting={dockDisplay?.waiting} />, [dockDisplay]);

    const RenderAssignedVehicles = useMemo(
        () =>
            dockDisplay?.assigned?.map((item) => (
                <ManagementDockLedDisplayAssignedVehicles
                    key={item.transportUnitNumber}
                    vehicle={item}
                />
            )),
        [dockDisplay],
    );
    return (
        <>
            <div className={style.generalContainer}>
                <div className={style.waiting}>
                    <div className={style.dataGrid}>
                        {RenderWaitingVehicles}
                    </div>
                </div>

                <div className={style.assigned}>
                    <div className={style.data}>{RenderAssignedVehicles}</div>
                </div>
            </div>
        </>
    );
};
